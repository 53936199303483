import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Subject} from "rxjs";

@Component({
  selector: 'app-add-hotel',
  templateUrl: './add-hotel.component.html',
  styleUrls: ['./add-hotel.component.scss']
})
export class AddHotelComponent extends BsModalRef {
  public onClose: Subject<boolean> = new Subject();

  constructor() {
    super();
  }

  addObject(): void {
    this.onClose.next(true);
    this.hide();
  }

  closeModal(): void {
    this.onClose.next(false);
    this.hide();
  }
}
