<div class="modal-body">
  <h5 class="modal-title mb-2">{{title|translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
    <i class="fa fa-times"></i>
  </button>
  {{message|translate}}
  <div class="text-center mt-2">
    <button class="btn btn-danger p-2" [disabled]="processing" (click)="removeTariff()">{{'yes'|translate}}</button>
  </div>
</div>
