import {BehaviorSubject} from 'rxjs';
import {Dictionary} from './Dictionary';
import {Room} from './Room';
import {Tariff} from './Tariff';

export const languageSubs = new BehaviorSubject('uk');

export class LanguageTranslate {
  static get(translations: any[], key: string) {
    return (translations.find(t => t.language === languageSubs.value) || {})[key] || '';
  }
  static room(room: Room) {
    return (room.hotelRoomTranslations.find(t => t.language === languageSubs.value) || {hotelRoomSelfname: ''}).hotelRoomSelfname;
  }

  static dictionary(dictionary: Dictionary) {
    return (dictionary.translations.find(t => t.language === languageSubs.value) || {title: ''}).title;
  }

  static tariff(tariff: Tariff) {
    return (tariff.hotelTariffTranslation.find(t => t.language === languageSubs.value) || {hotelTariffTitle: ''}).hotelTariffTitle;
  }
}
