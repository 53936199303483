<table *ngIf="rooms.length > 0" #table class="table table-responsive">
  <thead>
  <tr #daystable class="table-header table-header-bordered">
    <td class="center-text">Найближчі 5 днів</td>
    <td class="center-text" [ngClass]="{'text-warning' : day.day() === 6 || day.day() === 0}" *ngFor="let day of days">
      <div>{{day.date()}}</div>
      <div>{{day['display']}}</div>
    </td>
  </tr>
  </thead>
  <tbody *ngFor="let room of rooms; let r = index">

  <tr class="room">
    <td class="room-title">
      <a [routerLink]="['/room', room.hotelRoom.hotelRoomId]">
        <span>{{ translateRoom(room.hotelRoom) }}</span>
      </a>
    </td>
    <td class="room-available"
        [ngClass]="getBackground(getRoomAvailable(day, room.hotelRoom.hotelRoomId).hotelRoomsAvailable)"
        *ngFor="let day of days">
      <input class="input-field"
             [ngStyle]="setMyStyles(getRoomAvailable(day, room.hotelRoom.hotelRoomId).hotelRoomsAvailable)"
             [(ngModel)]="getRoomAvailable(day, room.hotelRoom.hotelRoomId).hotelRoomsAvailable"
             (ngModelChange)="countChanged()"
             (blur)="modifyCount(day, $event, room.hotelRoom.hotelRoomId)"
             (keypress)="checkCharCount($event, day, room.hotelRoom.hotelRoomId)">
    </td>
  </tr>
  </tbody>
</table>
