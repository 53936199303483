import {Component, Inject, Input} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() isAllHotelsPage = false;

  hotel: string = "";

  constructor(@Inject(Router) private router: Router) {
    let hotel = localStorage.getItem('hotel');
    if (hotel && hotel.length > 0) {
      this.hotel = JSON.parse(hotel).hotelInfoTranslations[0]?.hotelName;
    }
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['/auth/login']).then();
  }
}
