import {Component, Inject, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {DEFAULT_HOTEL_DATA} from "./default-hotel.constant";
import {NotificationService} from "../../shared/services/notification.service";
import {HotelService} from "../../shared/services/hotel.service";
import {ApiBaseService} from "../../shared/services/api-base.service";
import {Hotel} from "../../models/Hotel";
import {TableRoom} from "../../models/TablePrices";
import * as moment from "moment/moment";
import {forkJoin} from "rxjs";
import {map} from "rxjs/operators";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {RemoveHotelComponent} from "./remove-hotel/remove-hotel.component";
import {AddHotelComponent} from "./add-hotel/add-hotel.component";

@Component({
  selector: 'app-all-objects',
  templateUrl: './all-objects.component.html',
  styleUrls: ['./all-objects.component.scss']
})
export class AllObjectsComponent implements OnInit {

  userHotels: Hotel[] = [];
  total = 0;
  dataOffset = 0;
  userId = '';
  rooms: TableRoom[][] = [];
  private _timeout = null
  private _timeout_unlock = null
  loaded = false
  period: any = {
    from: moment(),
    to: moment().add(5, 'days')
  };
  createLoading = false;
  private bsModalRef: BsModalRef;

  constructor(@Inject(ApiBaseService) private apiService: ApiBaseService,
              private hotelService: HotelService,
              private modalService: BsModalService,
              @Inject(NotificationService) private notification: NotificationService) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    localStorage.removeItem('hotel');
    this.getUserHotels();
  }

  toHotelInfo(hotel: Hotel) {
    this.hotelService.findHotel(hotel.hotelId, '/tariff-list/review');
  }

  getUserHotels() {
    this.apiService.get(environment.apiUrl+`hotels/user/${this.userId}`).subscribe(res => {
      this.total = res['hotels'].length;
      this.userHotels = res['hotels'];
      this.dataOffset = this.userHotels.length;
      console.log(this.userHotels);

      const roomRequests = this.userHotels.map((hotel, index) =>
        this.apiService.get(environment.apiUrl + `hotels/${hotel.hotelId}/tariff-table?dateFrom=` +
          moment(this.period.from).format('YYYY-MM-DD') +
          '&dateTo=' + moment(this.period.to).format('YYYY-MM-DD')
        ).pipe(
          map((res) => ({ index, res })) // Зберігаємо індекс і результат
        )
      );

      forkJoin(roomRequests).subscribe({
        next: (results) => {
          results.forEach(({ index, res }) => {
            this.rooms[index] = res;
            console.log(res);
          });
        },
        error: (error) => {
          this.loaded = false;
        },
        complete: () => {
          this.loaded = false;
          console.log(this.rooms);
        }
      });
    })
  }

  createNewHotel() {
    this.createLoading = true;
    this.apiService.post({ ...DEFAULT_HOTEL_DATA }, environment.apiUrl+`hotels`).subscribe(res => {
      this.createLoading = false;
      this.hotelService.findHotel(res['hotelId']);
    })
  }

  onShowAddModal() {
    this.bsModalRef = this.modalService.show(AddHotelComponent);

    const modalComponent = this.bsModalRef.content as AddHotelComponent;

    modalComponent.onClose.subscribe((result: boolean) => {
      if (result) {
        this.createNewHotel();
      }
      this.bsModalRef.hide();
    });
  }

  onShowDeleteModal(hotel: Hotel) {
    this.bsModalRef = this.modalService.show(RemoveHotelComponent);

    const modalComponent = this.bsModalRef.content as RemoveHotelComponent;

    modalComponent.onClose.subscribe((result: boolean) => {
      if (result) {
        this.removeHotel(hotel);
      }
      this.bsModalRef.hide();
    });
  }

  removeHotel(hotel: Hotel) {
    this.apiService.delete(environment.apiUrl+`hotels/${hotel.hotelId}`).subscribe(res => {
      this.userHotels = this.userHotels.filter((item) => item.hotelId != hotel.hotelId)
      this.dataOffset = this.userHotels.length;
    })
  }
}
