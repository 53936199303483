<div class="modal-body" *ngIf="tariff">
  <h5 class="modal-title mb-2">{{(roomsCount ? 'tariff.roomsCountSet' : 'tariff.prices') | translate}}</h5>
  <button type="button" class="close" aria-label="Close"  (click)="modalRef.hide()">
    <i class="fa fa-times"></i>
  </button>
<form [formGroup]="ruleGroup" class="fieldList">
  <div class="inputField">
    <div class="dates">
      <input [bsConfig]="dateConfig" bsDatepicker formControlName="dateFrom">
      <input  [bsConfig]="dateConfig" bsDatepicker formControlName="dateTo">
    </div>
    <label>{{'tariff.period'|translate}}</label>
  </div>

  <ng-container *ngIf="ruleGroup.get('hotelRoomQuantityTotal').enabled">
    <div class="inputField">
      <input type="number" formControlName="hotelRoomQuantityTotal">
      <label>
        {{'tariff.roomsCount'|translate}}
      </label>
    </div>
  </ng-container>

  <ng-container *ngIf="ruleGroup.get('hotelRoomPaxPrices').enabled">
    <ng-container *ngFor="let pax of paxArray.controls">
      <div [formGroup]="pax">
        <div class="inputField">
          <input type="number" formControlName="hotelRoomPaxPriceRegular">
          <label>
            {{'tariff.priceFor'|translate}}
            {{pax.get('hotelRoomPaxPricePersonQuantity').value}}
            {{'tariff.man'|translate}}
          </label>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="ruleGroup.get('daysOfWeek').enabled">
    <div class="topicHeader">{{'tariff.weekDays'|translate}} <input type="checkbox" (click)="checkDays($event)"></div>
    <div class="weekDays">
      <div *ngFor="let day of week" class="day">
        <input id="{{day}}" type="checkbox" [checked]="checkedDay(day)" (click)="checkDay(day)">
        <label for="{{day}}">{{'week.' + day|translate}}</label>
      </div>
    </div>
  </div>

  <div class="text-center">
    <button type="submit" class="accept" [disabled]="ruleGroup.invalid || saveProcess" (click)="save()">{{'save'|translate}}</button>
  </div>
</form>
</div>
