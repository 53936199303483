import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/utils/auth-guard';
import { NbAuthComponent } from "@nebular/auth";
import { MainLayoutComponent } from "./layout/main-layout/main-layout.component";
import { HowToComponent } from './how-to/how-to.component';
import {AllObjectsComponent} from "./modules/all-objects/all-objects.component";


const routes: Routes = [
  {
    path: 'feedback',
    loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule),
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'all'
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    loadChildren: () =>
      import('./auth/auth.module').then(imported => imported.AuthModule),
  },
  {
    path: 'all',
    component: AllObjectsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'general', component: MainLayoutComponent,
    loadChildren: () => import('./modules/general/general.module').then(m => m.GeneralModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tariff', component: MainLayoutComponent,
    loadChildren: () => import('./modules/tariffs/tariff/tariff.module').then(m => m.TariffModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tariff/:roomId/:id', component: MainLayoutComponent,
    loadChildren: () => import('./modules/tariffs/tariff/tariff.module').then(m => m.TariffModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tariff-list', component: MainLayoutComponent,
    loadChildren: () => import('./modules/tariffs/tariff-list/tariff-list.module').then(m => m.TariffListModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'room', component: MainLayoutComponent,
    loadChildren: () => import('./modules/rooms/room/room.module').then(m => m.RoomModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'room/:id', component: MainLayoutComponent,
    loadChildren: () => import('./modules/rooms/room/room.module').then(m => m.RoomModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'room-list', component: MainLayoutComponent,
    loadChildren: () => import('./modules/rooms/room-list/room-list.module').then(m => m.RoomListModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'photo', component: MainLayoutComponent,
    loadChildren: () => import('./modules/photo/photo.module').then(m => m.PhotoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'discount', component: MainLayoutComponent,
    loadChildren: () => import('./modules/discount/discount.module').then(m => m.DiscountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'discount/:id', component: MainLayoutComponent,
    loadChildren: () => import('./modules/discount/discount.module').then(m => m.DiscountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'booking', component: MainLayoutComponent,
    loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'booking/:id', component: MainLayoutComponent,
    loadChildren: () => import('./modules/booking/info/info.module').then(m => m.InfoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'uk/how-to-use-extranet', component: HowToComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
