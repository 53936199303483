export const DEFAULT_HOTEL_DATA = {
  "hotelInfoTranslations": [
    {
      "language": "en",
      "hotelName": "My Hotel",
      "hotelDescription": "Будь ласка не вказуйте у цьому описі ваші телефони та мейли. Ми перечитуємо опис і видаляємо такі речі. \n" +
        "При створенні свого опису будь ласка дайте відповіді на наступні питання:  \n" +
        "Як довго працює ваш об`єкт? \n" +
        "Що собою являє ваш об`єкт? Детальніше. Наприклад: окрема будівля у житловій забудові, будиночки в лісі на березі річки, квартири у центрі міста, приміщення у окремому нежиловому будинку, і т.п. \n" +
        "Скільки усього номерів, та на які категорії поділені (хоча б основні) ? \n" +
        "Яка відстань у метрах до зупинки основного громадського транспорту (автобус, тролейбус, метро, трамвай) ? \n" +
        "Який транспорт (один основний) і скільки часу їде від основних пунктів прибуття туристів (аеропорт, залізничний вокзал, автовокзал) ? \n" +
        "Якщо ваші туристи їдуть до вас за конкретною памяткою, вкажіть як далеко ваш об`єкт від неї. Наприклад: до моря 15 хв. на тролейбусі, або до Бювету №1 300 метрів, або до найближчого лижного витягу №9 - 500 метрів (є маршрутка).   \n" +
        " \n" +
        "Якщо не знаєте англійську, ми перекладемо ваш опис самостійно.  \n" +
        " \n" +
        "Все інше Ваші гості побачать на Ваших фото.",
      "hotelAddressStreet": "За наявності, вкажіть вулицю та № будинку",
      "hotelAddressCity": "Kyiv"
    },
    {
      "language": "uk",
      "hotelName": "Мій Готель",
      "hotelDescription": "Будь ласка не вказуйте у цьому описі ваші телефони та мейли. Ми перечитуємо опис і видаляємо такі речі. \n" +
        "При створенні свого опису будь ласка дайте відповіді на наступні питання:  \n" +
        "Як довго працює ваш об`єкт? \n" +
        "Що собою являє ваш об`єкт? Детальніше. Наприклад: окрема будівля у житловій забудові, будиночки в лісі на березі річки, квартири у центрі міста, приміщення у окремому нежиловому будинку, і т.п. \n" +
        "Скільки усього номерів, та на які категорії поділені (хоча б основні) ? \n" +
        "Яка відстань у метрах до зупинки основного громадського транспорту (автобус, тролейбус, метро, трамвай) ? \n" +
        "Який транспорт (один основний) і скільки часу їде від основних пунктів прибуття туристів (аеропорт, залізничний вокзал, автовокзал) ? \n" +
        "Якщо ваші туристи їдуть до вас за конкретною памяткою, вкажіть як далеко ваш об`єкт від неї. Наприклад: до моря 15 хв. на тролейбусі, або до Бювету №1 300 метрів, або до найближчого лижного витягу №9 - 500 метрів (є маршрутка).   \n" +
        " \n" +
        "Якщо не знаєте англійську, ми перекладемо ваш опис самостійно.  \n" +
        " \n" +
        "Все інше Ваші гості побачать на Ваших фото.",
      "hotelAddressStreet": "За наявності, вкажіть вулицю та № будинку",
      "hotelAddressCity": "Київ"
    },
  ],
  "hotelAddressPostcode": "01001",
  "hotelAddressCountry": "UA",
  "hotelAddressLongLat": {
    "lat": 50.450765,
    "long": 30.522754
  },
  "hotelType": "hotelTypeHotel",
  "hotelTypeStars": 5,
  "hotelInsiteContacts": [
    {
      "language": "uk",
      "email": "sales@examplehotel.com",
      "phoneNumber": "+380(123)456-78-90"
    }
  ],
  "hotelServices": [
    "hotelServices0014",
    "hotelServices0112"
  ],
  "hotelTax": {
    "type": "hotelTaxIncluded",
  },
  "hotelCreditCard": ["VISA", "MASTERCARD"],
  "hotelAddBedRequest": false,
  "hotelTimeZone": "Europe/Kyiv",
  "hotelCheckInTime": 870,
  "hotelCheckOutTime": 720,
  "hotelTotalRoomQuantity": 1
}
