import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ReviewService } from '../../services/review.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TableRoom, TableTariff } from 'src/app/models/TablePrices';
import {tableEvent} from '../../../model/TableEvent';
import { DatePipe } from '@angular/common';
import { TariffLockData } from './tariff-lock.interface';

@Component({
  selector: 'app-tarif-lock',
  templateUrl: './tariff-lock.component.html',
  styleUrls: ['./tariff-lock.component.scss']
})
export class TariffLockComponent implements OnInit {
  @Input() tariff: TableTariff;
  @Input() room: TableRoom;
  @Input() period: any;
  @Input() isUnlock = false;
  isSuccess: boolean;
  minDate = new Date();
  dateFrom = new Date();
  dateTo = new Date();
  minDateF = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  colorTheme = 'theme-blue';
  constructor(
    public modalRef: BsModalRef,
    private reviewService: ReviewService,
    public datePipe: DatePipe
  ) {
    this.bsConfig = Object.assign({}, {
      containerClass: this.colorTheme,
      showWeekNumbers: false,
      dateInputFormat: 'DD.MM.YYYY',
      displayMonths: 2
    });
  }

  ngOnInit(): void {
    console.log(this.tariff);
    console.log(this.room);
    console.log(this.period);
    this.dateFrom = new Date(this.period.from),
    this.dateTo = new Date(this.period.to)
  }


  dateChanged(value) {
    if (value !== null) {
      const day = new Date();
      day.setFullYear(value.getFullYear(), value.getMonth(), value.getDate() + 1);
      this.minDate = value;
      if (value > this.period[1]) {
        this.period[1] = day;
      }
    }
  }
  submitFormClose() {
    const data: TariffLockData = {
      dateFrom: this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'),
      dateTo: this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'),
      hotelTariffPriceEnabled: this.isUnlock,
    }
    console.log(data);
    this.reviewService.postLock(this.room.hotelRoom.hotelRoomId, this.tariff.hotelTariff.hotelTariffId, data)
      .subscribe(data => this.onSuccessSending());
  }
  getTranslation(room: TableRoom, language: string): string {
    const translation = room.hotelRoom.hotelRoomTranslations.find(el => el.language === language);
    if (!translation) {
      return '';
    }
    return translation.hotelRoomSelfname;
  }
  onSuccessSending(): void {
    this.isSuccess = true;
    this.modalRef.hide();
    tableEvent.next();
  }
}
