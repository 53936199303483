<div class="modal-body">
  <h5 class="modal-title mb-2">Ви впевнені, що хочете видалити цей об'єкт?</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <i class="fa fa-times"></i>
  </button>
  <div class="text-center mt-2 buttons">
    <button class="btn btn-danger p-2" (click)="deleteObject()">{{'yes'|translate}}</button>
    <button class="btn btn-secondary p-2" (click)="closeModal()">{{'no'|translate}}</button>
  </div>
</div>
