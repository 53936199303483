import { Inject, Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { Hotel } from '../../models/Hotel';
import { Constants } from '../utils/Constants';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Room } from '../../models/Room';

@Injectable({
  providedIn: 'root'
})
export class HotelService {
  hotel: Hotel;
  constructor(@Inject(ApiBaseService) private apiService: ApiBaseService,
    @Inject(Router) private router: Router) {
  }

  hotelCache() {
    let _hotel = localStorage.getItem('hotel');
    if (_hotel && _hotel.length > 0)
      this.hotel = JSON.parse(_hotel);
    if (this.hotel) {
      this.apiService.formAPi(this.hotel.hotelId);
    }
  }

  findHotel(hotelId: string, route?: string) {
    this.apiService.get(Constants.API_HOTELS + '/' + hotelId)
      .subscribe(res => {
        if(res.hotelInfoTranslations[0].hotelAddressStreet == "Address" || res.hotelInfoTranslations[0].hotelAddressStreet == "Адреса"){
          res.hotelInfoTranslations[0].hotelAddressStreet = "За наявності, вкажіть вулицю та № будинку" // треба поміняти це зі стторони беку
          res.hotelInfoTranslations[1].hotelAddressStreet = "За наявності, вкажіть вулицю та № будинку" // треба поміняти це зі стторони беку
        }

        this.setHotel(res);
        this.apiService.formAPi(res.hotelId);
        this.router.navigateByUrl(route ?? 'general').then();
      });
  }

  setHotel(hotel: Hotel) {
    this.hotel = hotel;
    localStorage.setItem('hotel', JSON.stringify(this.hotel));
  }

  updateHotel() {
    this.apiService.patch(this.hotel, Constants.API_HOTELS + '/' + this.hotel.hotelId)
      .subscribe(res => {
        this.setHotel(res);
      });
  }

  getHotel() { return this.hotel; }

  getHotelId() {
    return this.hotel.hotelId;
  }

  getRoomList(): Observable<Room[]> {
    return this.apiService.get(Constants.API_HOTELS + '/' + this.getHotelId() + '/rooms');
  }

  createHotel() {
    const data = {
      hotelInfoTranslations: [
        {
          language: 'en',
          hotelName: 'Example Hotel',
          hotelDescription: 'Example Hotel example example example',
          hotelAddressStreet: 'Jefferson St., 1',
          hotelAddressCity: 'New York'
        }
      ],
      hotelAddressPostcode: 'DS 0110',
      hotelAddressCountry: 'US',
      hotelAddressLongLat: {
        lat: 32.345,
        long: 52.334
      },
      hotelType: 'hotelTypeHotel',
      hotelTypeStars: 5,
      hotelInsiteContacts: [
        {
          language: 'en',
          title: 'Reception',
          description: 'Reception',
          contactPerson: 'John Doe',
          email: 'sales@examplehotel.com',
          phone: '(123) 45-67-8901'
        }
      ],
      hotelServices: [
        'hotelServices0014',
        'hotelServices0112'
      ],
      hotelTax: {
        type: 'hotelTaxNotIncluded',
        amount: 12.5,
        currency: 'USD',
        percent: true
      },
      hotelCreditCard: [
        'VISA',
        'MASTERCARD'
      ],
      hotelChildPolicy: {
        type: 'hotelChildPolicyChildAllowed',
        data: [{ yearsFrom: 1, yearsTo: 16, bedType: 'roomBedOneSize', price: 20, currency: 'UAH' }]
      },
      hotelTotalRoomQuantity: 140,
      hotelCheckInTime: 870,
      hotelCheckOutTime: 720
    };
    return this.apiService.post(data, Constants.API_HOTELS);
  }
}
