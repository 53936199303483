import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ApiBaseService} from '../../../../../../shared/services/api-base.service';
import {NotificationService} from '../../../../../../shared/services/notification.service';
import {TableTariff} from '../../../../../../models/TablePrices';
import {tableEvent} from '../../../model/TableEvent';
import {WeekDays} from '../../../../../../models/Days';
import {DateConfig} from '../../../../../../models/DateConfig';
import * as moment from 'moment';

@Component({
  selector: 'app-tariff-discount',
  templateUrl: './tariff-discount.component.html',
  styleUrls: ['./tariff-discount.component.scss']
})
export class TariffDiscountComponent implements OnInit {
  ruleGroup: FormGroup;
  tariff: TableTariff;
  period: any;
  week = WeekDays;
  saveProcess = false;
  dateConfig = DateConfig;
  constructor(@Inject(FormBuilder) private fb: FormBuilder,
              public modalRef: BsModalRef,
              @Inject(ApiBaseService) private apiService: ApiBaseService,
              @Inject(NotificationService) private notification: NotificationService) {
    this.ruleGroup = this.fb.group({
      dateFrom: [null, Validators.required],
      dateTo: [null, [Validators.required]],
      daysOfWeek: [null, Validators.required],
      discountPercent: [0, Validators.min(0)]
    });
  }

  ngOnInit() {
    this.ruleGroup.patchValue({
      dateFrom: new Date(this.period.from),
      dateTo: new Date(this.period.to)
    });
  }

  save() {
    this.saveProcess = true;
    const data = this.ruleGroup.value;
    data.tariffIds = [this.tariff.hotelTariff.hotelTariffId];
    data.dateFrom = moment(data.dateFrom).format('yyyy-MM-DD');
    data.dateTo = moment(data.dateTo).format('yyyy-MM-DD');

    this.apiService.put(data, this.apiService.roomApi + '/tariffs/discount')
      .subscribe(() => {
        this.notification.ok();
        tableEvent.next();
        this.modalRef.hide();
      }, () => {
        this.notification.fail();
        this.saveProcess = false;
      });
  }

  checkedDay(day: string) {
    return this.ruleGroup.get('daysOfWeek').value && this.ruleGroup.get('daysOfWeek').value.includes(day);
  }

  checkDay(day: string) {
    let value = this.ruleGroup.get('daysOfWeek').value;
    if (value) {
      const ind = value.indexOf(day);
      ind !== -1 ? value.splice(ind, 1) : value.push(day);
    } else {
      value = [day];
    }
    this.ruleGroup.get('daysOfWeek').patchValue(value);
  }

  checkDays(e) {
    this.ruleGroup.get('daysOfWeek').patchValue(e.target.checked ? ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] : []);
  }
}
