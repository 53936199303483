import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import * as moment from 'moment';
import {ApiBaseService} from '../../../../../shared/services/api-base.service';
import {environment} from "../../../../../../environments/environment";

@Injectable()
export class ReviewService extends ApiBaseService {

  public getRoomsWithTariffs(period: any): Observable<any> {

    const params = new HttpParams()
      .set('from', period.from.format('YYYY-MM-DD'))
      .set('to', period.to.format('YYYY-MM-DD'));

    return this.getWithParams('rooms/review', {params: params});
  }

  public postPriceForPeriod(id: number, data: any): Observable<any> {
    return this.post(data, 'tariffs/change-price-for-period');
  }

  public postAvailableForPeriod(id: number, data: any): Observable<any> {
    return this.post(data, 'tariffs/' + id + '/close-sales-for-period');
  }

  public postLock(roomId: string, tariffId: string, data: any): Observable<any> {
    const hotelId = JSON.parse(localStorage.getItem('hotel')).hotelId;
    this.formAPi(hotelId);
    const url = this.roomApi + `/${roomId}/tariffs/${tariffId}/prices/period`;
    return this.post(data, url);
  }

  postWorkPeriod(id: number, data: any): Observable<any> {
    return this.post(data, 'tariffs/' + id + '/extend');
  }

  public postCountForPeriod(id: number, data: any): Observable<any> {
    return this.post(data, 'rooms/' + id + '/change-amount-for-period');
  }

  public convertDate(day: Date) {
    return moment(day).format('YYYY-MM-DD');
  }

  public convertPeriod(periodDate: any): any {
    const period: { [k: string]: any } = {};
    period.from = moment(periodDate[0]).format('YYYY-MM-DD');
    period.to = moment(periodDate[1]).format('YYYY-MM-DD');

    return period;
  }

  public changePrice(data: any): Observable<any> {
    return this.post(data, 'tariff-day/' + data.id + '/change-price');
  }

  public changeCount(data: any, count: number, roomId: number, currentHotelId?): Observable<any> {
    let requestData = {
      dateFrom: data.FDK,
      dateTo: data.FDK,
      hotelRoomsAvailable: count,
      daysOfWeek: []
    };
    let hotel = localStorage.getItem('hotel');
    let hotelId = '';
    if(hotel) {
      hotelId = JSON.parse(localStorage.getItem('hotel')).hotelId;
    } else {
      hotelId = currentHotelId;
    }
    return this.put(JSON.stringify(requestData), environment.apiUrl + `hotels/${hotelId}` + `/rooms/${roomId}/availability`);
  }

  public deleteCurrentDiscount(data: any, roomId: number, hotelTariffId: number, discount: number): Observable<any> {
    let holetId = JSON.parse(localStorage.getItem('hotel')).hotelId;
    return this.delete(environment.apiUrl + `hotels/${holetId}` + `/rooms/${roomId}/tariffs/${hotelTariffId}/discount/${discount}`);
  }

  public cancelDiscount(id: number): Observable<any> {
    return this.post({}, 'discount/' + id + '/remove');
  }

  public postCloseForSales(id: number): Observable<any> {
    return this.post({}, `tariff-day/${id}/close`);
  }

  public removeTariff(id: number): Observable<any> {
    return this.post({}, 'tariffs/' + id + '/remove');
  }
}
