import {Component, Inject, Input, AfterViewInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {tableEvent} from '../../../model/TableEvent';
import {TableTariff} from '../../../../../../models/TablePrices';
import {ApiBaseService} from '../../../../../../shared/services/api-base.service';
import {NotificationService} from '../../../../../../shared/services/notification.service';

@Component({
  selector: 'app-remove-tariff',
  templateUrl: './remove-tariff.component.html',
  styleUrls: ['./remove-tariff.component.scss']
})
export class RemoveTariffComponent implements AfterViewInit {
  @Input() tariff: TableTariff;
  @Input() roomId: string;
  @Input() discount = false;
  processing = false;
  title: string;
  message: string;
  constructor(public modalRef: BsModalRef,
              @Inject(NotificationService) private notification: NotificationService,
              @Inject(ApiBaseService) private apiService: ApiBaseService) {
  }

  ngAfterViewInit(): void {
    this.title = 'tariffList.deleteTariff';
    this.message = this.discount ? 'tariffList.sureToDeleteDiscount' : 'tariffList.sureToDelete';
  }

  removeTariff() {
    this.processing = true;
    const api = this.discount ? this.apiService.roomApi + '/tariffs/discount/clear' : this.apiService.roomApi + '/' + this.roomId + '/tariffs/' + this.tariff.hotelTariff.hotelTariffId;
    const request = this.discount ? this.apiService.post([this.tariff.hotelTariff.hotelTariffId], api) : this.apiService.delete(api);
    request.subscribe(() => {
        this.modalRef.hide();
        tableEvent.next();
      }, () => {
        this.notification.fail();
        this.processing = false;
      });
  }
}
