import {Component, Inject, OnInit} from '@angular/core';
import {TableTariff} from '../../../../../../models/TablePrices';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WeekDays} from '../../../../../../models/Days';
import {DateConfig} from '../../../../../../models/DateConfig';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NotificationService} from '../../../../../../shared/services/notification.service';
import {ApiBaseService} from '../../../../../../shared/services/api-base.service';
import * as moment from 'moment';
import {tableEvent} from '../../../model/TableEvent';
import {Room} from '../../../../../../models/Room';

@Component({
  selector: 'app-tariff-rule',
  templateUrl: './tariff-rule.component.html',
  styleUrls: ['./tariff-rule.component.scss']
})
export class TariffRuleComponent implements OnInit {
  room: Room;
  tariff: TableTariff;
  pax: number;
  roomsCount: boolean;
  period: any;
  ruleGroup: FormGroup;
  paxArray: FormArray;
  week = WeekDays;
  saveProcess = false;
  dateConfig = DateConfig;
  constructor(@Inject(FormBuilder) private fb: FormBuilder,
              public modalRef: BsModalRef,
              @Inject(ApiBaseService) private apiService: ApiBaseService,
              @Inject(NotificationService) private notification: NotificationService) {
    this.paxArray = this.fb.array([]);
    this.ruleGroup = this.fb.group({
      dateFrom: [null, Validators.required],
      dateTo: [null, [Validators.required]],
      daysOfWeek: [null, Validators.required],
      hotelRoomPaxPrices: this.paxArray,
      hotelRoomQuantityTotal: [null, Validators.required]
    });
    this.ruleGroup.get('hotelRoomQuantityTotal').disable();
  }

  ngOnInit(): void {
    if (this.pax) {
      this.ruleGroup.get('daysOfWeek').disable();
      this.paxArray.push(this.newPaxGroup(this.pax));
    } else {
      for (let i = 1; i <= this.room.hotelRoomPax; i++) {
        this.paxArray.push(this.newPaxGroup(i));
      }
    }
    if (this.roomsCount) {
      this.ruleGroup.get('hotelRoomQuantityTotal').enable();
      this.ruleGroup.get('hotelRoomPaxPrices').disable();
    }
    this.ruleGroup.patchValue({
      dateFrom: new Date(this.period.from),
      dateTo: new Date(this.period.to)
    });
  }

  save() {
    this.saveProcess = true;
    const data = this.ruleGroup.value;
    data.dateFrom = moment(data.dateFrom).format('yyyy-MM-DD');
    data.dateTo = moment(data.dateTo).format('yyyy-MM-DD');

    if( this.roomsCount)
      data.hotelRoomsAvailable = data.hotelRoomQuantityTotal;

    if(this.roomsCount)  {
      this.apiService.put(data, this.apiService.roomApi + '/' + this.room.hotelRoomId + '/availability')
      .subscribe(() => {
        this.notification.ok();
        tableEvent.next();
        this.modalRef.hide();
      }, () => {
        this.notification.fail();
        this.saveProcess = false;
      });
    }else{
      this.apiService.post(data, this.apiService.roomApi + '/' + this.room.hotelRoomId + '/tariffs/' + this.tariff.hotelTariff.hotelTariffId + '/prices/generate')
      .subscribe(() => {
        this.notification.ok();
        tableEvent.next();
        this.modalRef.hide();
      }, () => {
        this.notification.fail();
        this.saveProcess = false;
      });
    }
  }

  checkedDay(day: string) {
    return this.ruleGroup.get('daysOfWeek').value && this.ruleGroup.get('daysOfWeek').value.includes(day);
  }

  checkDay(day: string) {
    let value = this.ruleGroup.get('daysOfWeek').value;
    if (value) {
      const ind = value.indexOf(day);
      ind !== -1 ? value.splice(ind, 1) : value.push(day);
    } else {
      value = [day];
    }
    this.ruleGroup.get('daysOfWeek').patchValue(value);
  }

  checkDays(e) {
    this.ruleGroup.get('daysOfWeek').patchValue(e.target.checked ? ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] : []);
  }

  newPaxGroup(pax: number) {
    return this.fb.group({
      hotelRoomPaxPricePersonQuantity: [pax, Validators.required],
      hotelRoomPaxPriceRegular: [0, [Validators.required, Validators.min(1)]]
    });
  }
}
