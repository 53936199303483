import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {TableRoom, TableTariff} from "../../../models/TablePrices";
import {TranslatePipe} from "@ngx-translate/core";
import {ApiBaseService} from "../../../shared/services/api-base.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {ReviewService} from "../../tariffs/tariff-list/tariffs/services/review.service";
import {Constants} from "../../../shared/utils/Constants";
import {extendMoment} from "moment-range";
import * as moment from "moment/moment";
import {
  TariffDiscountComponent
} from "../../tariffs/tariff-list/tariffs/modals/tariff-discount/tariff-discount.component";
import {RemoveTariffComponent} from "../../tariffs/tariff-list/tariffs/modals/remove-tariff/remove-tariff.component";
import {Room} from "../../../models/Room";
import {LanguageTranslate} from "../../../models/LanguageSubs";
import {TariffRuleComponent} from "../../tariffs/tariff-list/tariffs/modals/tariff-rule/tariff-rule.component";
import {
  RemoveDiscountComponent
} from "../../tariffs/tariff-list/tariffs/modals/remove-discount/remove-discount.component";
import {Price} from "../../../models/Price";
import {TariffLockComponent} from "../../tariffs/tariff-list/tariffs/modals/tariff-lock/tariff-lock.component";
import {Hotel} from "../../../models/Hotel";

@Component({
  selector: 'app-small-review-table',
  templateUrl: './small-review-table.component.html',
  styleUrls: ['./small-review-table.component.scss']
})
export class SmallReviewTableComponent {

  @Input() rooms: TableRoom[];
  @Input() hotel: Hotel;
  @Input() from: Date;
  @Input() period: any;
  @Output() update = new EventEmitter<string>();
  @Output() lock = new EventEmitter<string>();
  header;
  days;

  details = {};

  formatDayKey = 'FDK';
  isMobile = false
  // priceChangedSubject: Subject<{ day: any, tariff: TableTariff }> = new Subject()

  // notificationSubject: Subject<string> = new Subject()

  constructor(
    @Inject(TranslatePipe) private translatePipe: TranslatePipe,
    @Inject(ApiBaseService) private apiService: ApiBaseService,
    @Inject(NotificationService) private notification: NotificationService,
    @Inject(ChangeDetectorRef) private CDR: ChangeDetectorRef,
    private modalService: BsModalService,
    private reviewService: ReviewService
  ) {
    this.checkIfMobile();
  }

  @HostListener('window:resize')
  checkIfMobile(): void {
    this.isMobile = window.innerWidth <= Constants.mobileResolution;
  }

  toggleDetails(index) {
    if(!this.details[index]) {
      this.details[index] = true;
    } else {
      this.details[index] = !this.details[index];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rooms.currentValue) {
      this.prepareData();
    }
  }

  prepareData(): void {
    this.days = [];
    this.header = [];
    this.days = Array.from(extendMoment(moment).range(this.period.from, this.period.to).by('days'));
    const localeData = moment.localeData('uk');
    const months: Map<string, moment.Moment[]> = new Map<string, moment.Moment[]>();
    for (const day of this.days) {
      if (!months.has(day.format('MMMM'))) {
        months.set(day.format('MMMM'), []);
      }
      months.get(day.format('MMMM')).push(day);
    }
    months.forEach((value) => {
      this.header.push({month: value[0], count: value.length});
    });

    this.days.forEach(d => {
      d[this.formatDayKey] = d.format('YYYY-MM-DD');
      d['display'] = localeData.weekdaysShort(d)
    });

    this.rooms.forEach(r => {
      r.hotelRoom.hotelRoomPaxes = Array(r.hotelRoom.hotelRoomPax);
      r.hotelTariffs.forEach(t => {
        t.title = this.translatePipe.transform(t.hotelTariff.hotelCancellationPolicy.type) + '. \n';
        if (t.hotelTariff.hotelCancellationPolicy.type !== 'hotelCancellationPolicyNonRefundable') {
          t.title += this.translatePipe.transform('tariff.policy.p1');
          t.title += this.translatePipe.transform(t.hotelTariff.hotelCancellationPolicy.daysBeforeCheckInNoPenalty);
          t.title += this.translatePipe.transform('tariff.policy.p2');
          t.title += this.translatePipe.transform(t.hotelTariff.hotelCancellationPolicy.penaltyPercent);
          t.title += this.translatePipe.transform('tariff.policy.p3');
          t.title += this.translatePipe.transform(t.hotelTariff.hotelCancellationPolicy.typeOfPenaltyPercent);
          t.title += this.translatePipe.transform('tariff.policy.p4') + ' \n';
        }
        t.title += this.translatePipe.transform('tariff.mealType') + ' \n';
        t.title += this.translatePipe.transform(t.hotelTariff.hotelMealsPlan.type);
        if (t.hotelTariff.hotelMealsPlan.price > 0) {
          t.title += ' ' + t.hotelTariff.hotelMealsPlan.price + ' ';
          t.title += this.translatePipe.transform('tariff.mealTypeCost');
        }

        t.hotelTablePrices = new Map(t.hotelTariffPrices.map(p => {
          p.hotelRoomPaxPricesMap = new Map(p.hotelRoomPaxPrices.map(hrpp => [hrpp.hotelRoomPaxPricePersonQuantity, hrpp]))
          return [moment(p.hotelTariffPriceDate).format('YYYY-MM-DD'), p]
        }));
        if (t.hotelTariffPrices.some(p => p.hotelRoomPaxPrices[0].hotelRoomPaxPriceDiscountPercent)) {
          t.hotelTableDiscounts = new Set<number>();
          t.hotelTablePrices.forEach(p => {
            if (p.hotelRoomPaxPrices[0].hotelRoomPaxPriceDiscountPercent) {
              t.hotelTableDiscounts.add(p.hotelRoomPaxPrices[0].hotelRoomPaxPriceDiscountPercent);
            }
          });
          t.hotelTableDiscounts = Array.from(t.hotelTableDiscounts);
          t.hotelTableDiscounts.sort();
        }
      });
    });
  }

  discountModify(tariff: TableTariff) {
    this.modalService.show(TariffDiscountComponent, {initialState: {tariff, period: this.period}});
  }

  discountDelete(tariff: TableTariff) {
    this.modalService.show(RemoveTariffComponent, {class: 'modal-sm', initialState: {tariff, discount: true}});
  }

  translateRoom(room: Room) {
    return LanguageTranslate.room(room);
  }

  getPaxTariffDay(day: any, tariff: TableTariff, pax: number) {
    if (!tariff || !day) return null

    const hotelTablePrice = tariff.hotelTablePrices.get(day[this.formatDayKey])
    if (hotelTablePrice)
      return hotelTablePrice.hotelRoomPaxPricesMap.get(pax)

    return null;
  }

  getRoomAvailable(day: any, roomId: any) {
    const room = this.rooms.find(r => r.hotelRoom.hotelRoomId === roomId);
    const record = room.hotelRoomAvailability.find(r => moment(r.date).format('YYYY-MM-DD') === day[this.formatDayKey]);
    return record ? record : {hotelRoomsAvailable: 0};
  }

  getRoomTarriff(day: any, roomId: any) {
    const room = this.rooms.find(r => r.hotelRoom.hotelRoomId === roomId);
    const record = room?.hotelTariffs[0]?.hotelTariffPrices[0]?.hotelTariffPriceEnabled;
    return record;
  }

  getTariffDayDiscount(day: any, tariff: TableTariff, discount: number, pax: number) {
    const dayT = tariff.hotelTablePrices.get(day[this.formatDayKey]);
    if (dayT) {
      const paxT = dayT.hotelRoomPaxPrices.find(p => p.hotelRoomPaxPricePersonQuantity === pax);
      return paxT && paxT.hotelRoomPaxPriceDiscountPercent === discount ? paxT.hotelRoomPaxPriceDiscount : '';
    }
    return '';
  }

  tariffHasDiscount(tariff: TableTariff) {
    return tariff.hotelTariffPrices.some(p => p.hotelRoomPaxPrices[0].hotelRoomPaxPriceDiscountPercent);
  }

  editTariffPrices(room: Room, tariff: TableTariff, pax?: number) {
    this.modalService.show(TariffRuleComponent, {initialState: {room, tariff, pax, period: this.period}});
  }

  modifyCount(day: any, event: any, idRoom) {
    let count = Number(event.target.value);
    console.log(count);
    if (count < 0)
      return;

    this.reviewService.changeCount(day, count, idRoom, this.hotel.hotelId).subscribe(data => {
      this.unlockTable()
      if (data.status == 'success') {
        //this.update.emit('update');
      }
    });
  }

  getBackground(count: number) {
    return (count > 0 ? 'green-back' : 'btn-warning');
  }

  setMyStyles(count: number) {
    let styles = {
      'background-color': (count > 0 ? '#208052' : '#f4b400')
    };
    return styles;
  }

  changeCountForPeriod(room: Room, tariff: TableTariff) {
    this.modalService.show(TariffRuleComponent, {initialState: {room, tariff, roomsCount: true, period: this.period}});
  }

  checkChar(event, day: any, tariff: TableTariff, pax: number) {
    const _pax = this.getPaxTariffDay(day, tariff, pax + 1)
    if (event.keyCode === 13 && day && tariff) {
      // this.priceChangedSubject.next(null);
      if (_pax.changed && !_pax.saved) {
        _pax.saved = true
        this.savePrice(day, tariff);
      }
    }
    if (event.keyCode !== 46 && (event.keyCode < 47 || event.keyCode > 58)) {
      event.preventDefault();
    } else {
      if (_pax) {
        _pax.changed = true
        _pax.saved = false
        this.lockTable()
      }
    }
  }

  checkCharCount (event, day, hotelRoomId) {
    if (event.keyCode === 13) {
      this.modifyCount(day, event, hotelRoomId)
      return;
    }

    if (event.keyCode !== 46 && (event.keyCode < 47 || event.keyCode > 58)) {
      event.preventDefault();
    }
  }

  lockTable() {
    this.lock.emit('lock')
  }

  closeForSales(day: any, tariff: TableTariff): void {
    const dayT = tariff.hotelTablePrices.get(day[this.formatDayKey]);
    dayT.hotelTariffPriceEnabled = !dayT.hotelTariffPriceEnabled;
    const dayE = this.getPriceDTO(dayT);
    delete dayE.hotelRoomPaxPrices;
    this.apiService.put(dayE, this.getPriceUrl(dayT)).subscribe(null, () => {
      this.notification.fail();
    });
  }

  getClass(day: any, tariff: TableTariff, roomId: any): string {
    const room = this.rooms.find(r => r.hotelRoom.hotelRoomId === roomId);
    const record = room.hotelRoomAvailability.find(r => moment(r.date).format('YYYY-MM-DD') === day[this.formatDayKey]);

    const dayT = tariff.hotelTablePrices.get(day[this.formatDayKey]);

    if (!dayT) {
      return 'no-data';
    } else if (dayT && !dayT?.hotelTariffPriceEnabled) {
      return 'closed';
    } else if (dayT && dayT?.hotelTariffPriceEnabled && record?.hotelRoomsAvailable > 0) {
      return dayT.hotelTariffPriceEnabled ? 'available' : 'closed';
    } else if (record && record.hotelRoomsAvailable === 0) {
      return 'zeros';
    } else {
      return 'zeros';
    }
  }

  removeTariff(tariff: TableTariff, room: TableRoom) {
    const bsModalRef = this.modalService.show(RemoveTariffComponent, {class: 'modal-sm'});
    bsModalRef.content.tariff = tariff;
    bsModalRef.content.roomId = room.hotelRoom.hotelRoomId;
  }

  removeCurrentDiscount(tariff: TableTariff, room: TableRoom, discount: number) {
    const bsModalRef = this.modalService.show(RemoveDiscountComponent, {class: 'modal-sm'});
    bsModalRef.content.tariff = tariff;
    bsModalRef.content.roomId = room.hotelRoom.hotelRoomId;
    bsModalRef.content.currentDiscount = discount;
  }

  blurPrice(day: any, tariff: TableTariff, pax: number) {
    const _pax = this.getPaxTariffDay(day, tariff, pax + 1)
    // this.priceChangedSubject.next(null)
    if (_pax.changed && !_pax.saved) {
      _pax.saved = true;
      this.savePrice(day, tariff)
    }
  }

  savePrice(day: any, tariff: TableTariff) {
    const dayT = tariff.hotelTablePrices.get(day[this.formatDayKey]);
    this.apiService.put(this.getPriceDTO(dayT), this.getPriceUrl(dayT)).subscribe({
      next: data => {
        this.notification.ok();
        this.unlockTable();
      }, error: error => {
        this.notification.fail();
      }
    });
  }

  unlockTable() {
    this.lock.emit('unlock')
  }

  getPriceUrl(price: any) {
    return this.apiService.roomApi + '/' + price.hotelRoomId + '/tariffs/' + price.hotelTariffId + '/prices/' + price.hotelTariffPriceId;
  }

  getPriceDTO(price: Price) {
    const data = JSON.parse(JSON.stringify({
      hotelTariffPriceEnabled: price.hotelTariffPriceEnabled,
      hotelRoomPaxPrices: price.hotelRoomPaxPrices
    }));
    data.hotelRoomPaxPrices.map(p => {
      //delete p.hotelRoomPaxPriceDiscountPercent;
      // delete p.hotelRoomPaxPriceDiscount;
      p.hotelRoomPaxPriceRegular = Number(p.hotelRoomPaxPriceRegular);
    });
    return data;
  }

  countChanged() {
    this.lockTable()
  }

  priceChanged(day: any, tariff: TableTariff, pax: number) {
    const _pax = this.getPaxTariffDay(day, tariff, pax + 1)

    if (_pax) {
      _pax.changed = true
      _pax.saved = false
      this.lockTable()
    }
  }
  lockSales(tariff, room, isUnlock = false) {
    this.modalService.show(TariffLockComponent, {initialState: {tariff, period: this.period, room: room, isUnlock: isUnlock}});
  }
}
