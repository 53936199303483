<div class="modal-body" *ngIf="tariff">
  <h5 class="modal-title mb-2">{{ (isUnlock ? 'tariffList.openSales' : 'tariffList.closeSales')|translate}}</h5>
  <button type="button" class="close" aria-label="Close"  (click)="modalRef.hide()">
    <i class="fa fa-times"></i>
  </button>
  <form class="fieldList d-flex flex-column">
    <div class="h6 mb-3">{{getTranslation(room, 'uk')}} | {{tariff.hotelTariff.hotelTariffTitle}}</div>
    <div class="inputField">
      <div class="dates">
        <input [bsConfig]="bsConfig" bsDatepicker [(bsValue)]="dateFrom">
        <input  [bsConfig]="bsConfig" bsDatepicker [(bsValue)]="dateTo">
      </div>
      <label>{{'tariff.period'|translate}}</label>
    </div>

    <div class="text-center">
      <button type="submit" class="accept" (click)="submitFormClose()">{{'save'|translate}}</button>
    </div>
  </form>
</div>
