import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { LoginComponent } from './login/login.component';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientJsonpModule,
    HttpClientModule,
    HttpResponse
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DatePipe, LOCATION_INITIALIZED } from '@angular/common';
import { AuthGuard } from './shared/utils/auth-guard';
import { TokenInterceptor } from './shared/utils/token-interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
/*import {AgmCoreModule} from '@agm/core';*/

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ukLocale } from 'ngx-bootstrap/locale';
import { NbThemeModule, NbDialogModule, NbLayoutModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
    NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
    NbAuthJWTInterceptor,
    NbAuthJWTToken,
    NbAuthModule,
    NbPasswordAuthStrategy, NbPasswordAuthStrategyOptions
} from "@nebular/auth";
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from "ng-recaptcha";
import { environment } from "../environments/environment";
import { NgxCaptchaModule } from "ngx-captcha";
import { config } from "rxjs";
import { CustomNbPasswordAuthStrategy } from "./auth/custom-password-strategy";
import {
    getCustomDeepFromObject,
} from "./auth/custom-password-strategy";
import { GoogleMapsModule } from "@angular/google-maps";
import { OverlayContainer } from "@angular/cdk/overlay";
import localeUk from '@angular/common/locales/uk';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FeedbackComponent } from './feedback/feedback.component';
import { HowToComponent } from './how-to/how-to.component';
import { NbShareModule } from './nb-share/nb-share.module';
import {AllObjectsComponent} from "./modules/all-objects/all-objects.component";
import { SmallReviewTableComponent } from './modules/all-objects/small-review-table/small-review-table.component';
import {ReviewService} from "./modules/tariffs/tariff-list/tariffs/services/review.service";
import {RemoveHotelComponent} from "./modules/all-objects/remove-hotel/remove-hotel.component";
import { AddHotelComponent } from './modules/all-objects/add-hotel/add-hotel.component';

registerLocaleData(localeUk);
defineLocale('uk', ukLocale);

export function appTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () => new Promise<any>(resolve => {
        injector.get(LOCATION_INITIALIZED, Promise.resolve(null))
            .then(() => {
                translate.setDefaultLang('uk');
                translate.use('uk')
                    .subscribe(
                        () => undefined,
                        () => undefined,
                        () => resolve(null),
                    );
            });
    });
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        MenuComponent,
        LoginComponent,
        MainLayoutComponent,
        HowToComponent,
        AllObjectsComponent,
        SmallReviewTableComponent,
        RemoveHotelComponent,
        AddHotelComponent,
    ],
    imports: [
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        BrowserModule,
        /*    AgmCoreModule.forRoot({
              apiKey: 'AIzaSyBSbgGePitwHSVOpd0xnq5dYJcXIhyjy-A',
              libraries: ['places']
            }),*/
        GoogleMapsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        DatepickerModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: appTranslateLoader,
                deps: [HttpClient]
            }
        }),
        NbDialogModule.forRoot(),
        NbThemeModule.forRoot({ name: 'default' }),
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        TooltipModule.forRoot(),
        NgxCaptchaModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        NbLayoutModule,
        NbShareModule,
        NbEvaIconsModule,
        NbAuthModule.forRoot({
            strategies: [
                CustomNbPasswordAuthStrategy.setup({
                    name: 'email',
                    token: {
                        class: NbAuthJWTToken,
                        key: 'token.refresh_token',
                        getter: (
                            module: string,
                            res: HttpResponse<Object>,
                            options: NbPasswordAuthStrategyOptions
                        ) =>
                            // @ts-ignore
                            getCustomDeepFromObject(res.body, options.token.key),

                    },
                    baseEndpoint: environment.apiUrlShort,
                    login: {
                        endpoint: '/auth/login_check',
                        redirect: {
                            success: '/',
                            failure: '/',
                        },
                    },
                    register: {
                        endpoint: '/auth/register',
                        requireValidToken: false,
                        method: 'post',
                        redirect: {
                            success: '/auth/login',
                            failure: null,
                        },
                    },
                    requestPass: {
                        endpoint: '/auth/reset-password/request',
                        method: 'post',
                    },
                    refreshToken: {
                        endpoint: '/auth/token/refresh',
                        method: 'post',
                        requireValidToken: true,
                        redirect: {
                            success: null,
                            failure: null,
                        },
                        defaultErrors: ['Something went wrong, please try again.'],
                        defaultMessages: ['Your token has been successfully refreshed.'],
                    },
                }),
            ],
            forms: {},
        }),

    ],
    providers: [
        { provide: LOCALE_ID, useValue: "uk-UK" },
        DatePipe,
        TranslatePipe,
        AuthGuard,
        ReviewService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [TranslateService, Injector], multi: true },
        { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.recaptcha.siteKey, } as RecaptchaSettings, },
        {
            provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
            useValue: (req: any) => {
                if (
                    req.url == '/auth/login_check' ||
                    req.url == '/api/auth/token/revoke' ||
                    //req.url.includes('https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCVjemkUGdWbyQfk8gf5AgYdN5zUdSrmik')
                    req.url.includes('https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyC2gF7EZQ_X9RaoXayxz9jPvCkeOwWu7qI')
                ) {
                    return true;
                }
                if (req.url.includes('/auth/token/refresh')) {
                    return true;
                }
                return false;
            },
        },
        { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
        { provide: OverlayContainer },
        CustomNbPasswordAuthStrategy,
        Title
    ],
    exports: [

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
