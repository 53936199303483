import {Component, Inject, Input, AfterViewInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {tableEvent} from '../../../model/TableEvent';
import {TableTariff} from '../../../../../../models/TablePrices';
import {ApiBaseService} from '../../../../../../shared/services/api-base.service';
import {NotificationService} from '../../../../../../shared/services/notification.service';
import { ReviewService } from '../../services/review.service';

@Component({
  selector: 'app-remove-discount',
  templateUrl: './remove-discount.component.html',
  styleUrls: ['./remove-discount.component.scss']
})
export class RemoveDiscountComponent implements AfterViewInit {
  @Input() tariff: any;
  @Input() roomId: string;
  @Input() currentDiscount: number;
  @Input() discount = true;
  processing = false;
  title: string;
  message: string;
  constructor(public modalRef: BsModalRef,
    private reviewService: ReviewService,
              @Inject(NotificationService) private notification: NotificationService,
              @Inject(ApiBaseService) private apiService: ApiBaseService) {
  }

  ngAfterViewInit(): void {
    this.title = 'tariffList.deleteTariff';
    this.message = this.discount ? 'tariffList.sureToDeleteDiscountCurrent' : 'tariffList.sureToDelete';
  }

  removeDiscount() {
    this.processing = true;

    this.tariff.hotelTariffPrices.forEach(el => {
      el.hotelRoomPaxPrices.forEach(val => {
        val.hotelRoomPaxPriceDiscount = null;
        val.hotelRoomPaxPriceDiscountPercent = null;
      });
    });

    //const api = this.discount ? this.apiService.roomApi + '/tariffs/discount/clear' : this.apiService.roomApi + '/' + this.roomId + '/tariffs/' + this.tariff.hotelTariff.hotelTariffId;
    this.reviewService.deleteCurrentDiscount(this.tariff.hotelTariffPrices, this.tariff.hotelTariffPrices[0].hotelRoomId, this.tariff.hotelTariff.hotelTariffId, this.currentDiscount).subscribe(() => {
        this.modalRef.hide();
        tableEvent.next();
      }, () => {
        this.notification.fail();
        this.processing = false;
      });
  }
}
