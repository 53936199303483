import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Subject} from "rxjs";

@Component({
  selector: 'app-remove-hotel',
  templateUrl: './remove-hotel.component.html',
  styleUrls: ['./remove-hotel.component.scss']
})
export class RemoveHotelComponent extends BsModalRef {
  public onClose: Subject<boolean> = new Subject();

  constructor() {
    super();
  }

  deleteObject(): void {
    this.onClose.next(true);
    this.hide();
  }

  closeModal(): void {
    this.onClose.next(false);
    this.hide();
  }
}
