import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  rootElement: any;
  constructor(@Inject(TranslateService) private translate: TranslateService,
              @Inject(Title) private titleService: Title) {
    this.rootElement = document.getElementsByTagName('app-root')[0];
  }

  setTitle(key: string) {
    this.titleService.setTitle(this.translate.instant('title') + ' - ' + this.translate.instant(key));
  }

  ok() {
    const newDiv = document.createElement('div');
    newDiv.id = 'notification';
    newDiv.className = 'success';
    newDiv.innerText = this.translate.instant('notification.success');
    document.body.insertBefore(newDiv, this.rootElement);
    setTimeout(() => {
      newDiv.remove();
    }, 1800);
  }

  fail() {
    const newDiv = document.createElement('div');
    newDiv.id = 'notification';
    newDiv.className = 'fail';
    newDiv.innerText = this.translate.instant('notification.fail');
    document.body.insertBefore(newDiv, this.rootElement);
    setTimeout(() => {
      newDiv.remove();
    }, 1800);
  }
}
