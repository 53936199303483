import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

export const DateConfig: Partial<BsDatepickerConfig> = {
  containerClass: 'theme-blue',
  showWeekNumbers: false,
  // dateInputFormat: 'dd, D MMMM YYYY',
  dateInputFormat: 'DD MMMM YYYY',
  displayMonths: 1,
  minDate: new Date()
};

export const AnyDateConfig: Partial<BsDatepickerConfig> = {
  containerClass: 'theme-blue',
  showWeekNumbers: false,
  // dateInputFormat: 'dd, D MMMM YYYY',
  dateInputFormat: 'DD MMMM YYYY',
  displayMonths: 1,
};

